import * as React from 'react';
import { useState } from 'react'; // Import useState if not already imported
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import demovideo from '../assets/attempt2.mp4';

export default function Hero() {
  const [email, setEmail] = useState(''); // State to store email input
  const [submitted, setSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // New state for button click feedback
  const [showMessage, setShowMessage] = useState(false); // New state for success message

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitted || !email.trim()) return; // Check if email is empty

    try {
      setButtonClicked(true); // Set button as clicked immediately
      setSubmitted(true); // Disable the button to prevent multiple clicks

      const response = await fetch('/api/add-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setShowMessage(true); // Show success message
        setTimeout(() => {
          setSubmitted(false); // Re-enable button after 5 seconds
          setButtonClicked(false); // Reset button click feedback
          setShowMessage(false); // Optionally hide the message after a few seconds
        }, 1000);
      } else {
        console.error('Failed to submit email:', await response.json());
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      setSubmitted(false); // Re-enable button in case of error
      setButtonClicked(false);
    }
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        paddingTop: { xs: 10, sm: 10 },
        backgroundImage:
          theme.palette.mode === 'light'
            ? `linear-gradient(160deg, #CEE5FD 0%, #f5f6fb 50%,  ${alpha('#f5f6fb', 0.5)} 100%)`
            : `linear-gradient( #244b8a , ${alpha('#262245', 0)})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        overflow: 'hidden',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          position: 'relative',
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%', md: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(4rem, 10vw, 4.5rem)',
              fontWeight: 550,
            }}
          >
            Automate sourcing &nbsp;
            <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(3.5rem, 10vw, 3rem)',
                fontWeight: 550,
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.dark',
              }}
            >
              of mechanical components
            </Typography>
          </Typography>

          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ pt: 2, alignSelf: 'center', width: { sm: '100%', md: '85%' }, fontSize: 'clamp(1.2rem, 10vw, 1.2rem)' }}
          >
            Search across vendors & generate CAD files for off-the-shelf components with our AI toolkit built for hardware companies.
          </Typography>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            alignSelf="center"
            useFlexGap
            sx={{
              pt: 3,
              width: { xs: '100%', sm: 'auto', md: '70%' },
              margin: 'auto',
            }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Enter email to get early access"
              sx={{
                width: { xs: '100%', sm: 'auto', md: '70%' },
              }}
              inputProps={{
                autoComplete: 'off',
                'aria-label': 'Enter your email address',
              }}
              value={email} // Bind the value to state
              onChange={(e) => setEmail(e.target.value)} // Update state on input change
            />

            <Button
              variant="contained"
              color={buttonClicked ? 'secondary' : 'primary'} // Change color immediately when clicked
              onClick={handleSubmit}
              disabled={submitted}
            >
              Submit
            </Button>
          </Stack>

          {/* Success message */}
          {showMessage && (
            <Typography
              sx={{
                mt: 1,
                textAlign: 'center',
                color: 'success.main',
              }}
            >
              Your email has been submitted!
            </Typography>
          )}
        </Stack>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 600 },
            width: '90%',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow: `0 0 20px 3px rgba(255, 255, 255, 0.5)`,
            boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        >
          <video
            src={demovideo}
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              boxShadow: `0 0 20px 5px rgba(255, 255, 255, 0.5)`,
            }}
          >
            Your browser does not support the video tag.
          </video>
        </Box>
      </Container>
    </Box>
  );
}
